<template>
  <div class="flex flex-col items-center justify-center gap-4">
    <NuxtErrorBoundary>
      <slot />

      <div class="mx-auto shrink-0">
        <PieChart
          class="h-full w-full"
          :data="pieData"
          :options="options"
        />
      </div>

      <template #error="slotProps">
        <BaseButton @click="reloadCharts(slotProps)">
          {{ $t("Reload charts") }}
        </BaseButton>
      </template>
    </NuxtErrorBoundary>
  </div>
</template>

<script lang="ts" setup>
import { useChartStore } from '#/stores/chartStore'
import PieChart from '#components/charts/PieChart.vue'

const { pieData, options } = useChartStore()

const reloadCharts = ({ clearError }) => {
  clearError()
}
</script>
