<template>
  <section
    id="about"
    class="relative flex flex-col items-center justify-center gap-8 p-8 py-20 lg:flex-row lg:gap-4"
  >
    <div
      class="absolute h-full w-full bg-opacity-20 bg-[url('/coding-table.webp')] bg-cover bg-center bg-no-repeat opacity-5"
    />

    <BaseImage
      :alt="$t('Portrait picture of Benjamin Scharf')"
      class="z-10 max-w-xl"
      src="/portrait.webp"
    />

    <article class="z-10 space-y-4">
      <BaseHeading
        class="mb-4 text-center text-5xl dark:text-neutral-50 lg:text-left"
      >
        {{ $t('About Me') }}
      </BaseHeading>

      <BaseList
        class="pl-4"
        :list-infos="stackInfos"
      />

      <BaseButton
        class="w-full lg:w-fit"
        icon="i-heroicons-document-arrow-down"
        downloadable
        @click="downloadCV"
      >
        {{ $t('Download CV') }}
      </BaseButton>

      <BaseButton
        class="lg:ml-4 w-full lg:w-fit"
        icon="i-heroicons-document-arrow-down"
        variant="outline"
        downloadable
        :aria-label="$t('Read more about Benjamin Scharf')"
        @click="$router.push('/blog/benjamin-scharf')"
      >
        {{ $t('Read more') }}
      </BaseButton>
    </article>
  </section>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import CV from '@/assets/files/CV_Benjamin_Scharf.pdf'
import BaseList from '#components/library/list/BaseList.vue'
import BaseHeading from '#/domains/base/components/BaseText/BaseHeading.vue'

const { t } = useI18n()

const stackInfos = computed(() => [
  t('Positive mindset and passionate working attitude'),
  t('Proactive and solution oriented team player'),
  t('Eye on accessibility, best-practices conventions and code performance'),
  t('Consistently evolving new skills'),
])

const downloadCV = () => {
  const link = document.createElement('a')
  link.href = CV
  link.target = '_blank'
  link.download = 'CV_Benjamin_Scharf.pdf'

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
</script>
