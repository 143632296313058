import { defineStore } from 'pinia'
import api from '#/api/message'

import type { ContactFields } from '#/types/api/message'

const useMessageStore = defineStore('messageStore', {
  state() {
    return {
      messages: [],
    }
  },
  actions: {
    async submitForm(payload: ContactFields) {
      await api.submitFormMessage(payload)
    },
  },
})

export default useMessageStore
