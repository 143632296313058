<script setup lang="ts">
import { Pie } from 'vue-chartjs'
import type { ChartComponent } from 'chart.js'
import 'chart.js/auto'
// ************* TYPES ************* //
interface Props {
  data: {
    labels: string[]
    datasets: {
      label: string
      backgroundColor: string[]
      data: number[]
    }[]
  }
  options: {
    responsive: boolean
    maintainAspectRatio: boolean
    scales: {
      yAxes: {
        ticks: {
          beginAtZero: boolean
        }
      }[]
    }
  }
}

// ************* PROPS ************* //
defineProps<Props>()

const pieChart = ref<ChartComponent>()

// onBeforeUnmount(() => {
//   pieChart.value.chart.clear()
//   pieChart.value.chart.destroy()
// })
</script>

<template>
  <div>
    <Pie
      ref="pieChart"
      class="charts-container__pie"
      :data="data"
      :options="options"
    />
  </div>
</template>

<style scoped lang="scss"></style>
