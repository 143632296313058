<script lang="ts" setup>
import BaseHeading from '#/domains/base/components/BaseText/BaseHeading.vue'

const techIcons = [
  'i-logos-npm-icon',
  'i-logos-rubygems',
  'i-bxl-github',
  'i-logos-git-icon',
  'i-logos-gitlab',
  'i-logos-mattermost-icon',
  'i-logos-visual-studio-code',
  'i-logos-webstorm',
  'i-logos-figma',
  'i-logos-blender',
  'i-logos-docker-icon',
]

const frontEndIcons = [
  'i-logos-html-5',
  'i-logos-css-3',
  'i-logos-sass',
  'i-logos-tailwindcss-icon',
  'i-logos-javascript',
  'i-logos-typescript-icon',
  'i-logos-vue',
  'i-logos-nuxt-icon',
]

const backendIcons = [
  'i-logos-nodejs-icon',
  'i-logos-laravel',
  'i-logos-ruby',
  'i-logos-mongodb-icon',
  'i-logos-supabase-icon',
]
</script>

<template>
  <section
    id="stacks"
    class="py-20"
  >
    <div class="mx-4 max-w-6xl lg:mx-auto">
      <BaseHeading
        tag="h3"
        class="mb-8 text-center text-5xl font-semibold text-neutral-950 dark:text-white lg:text-left"
      >
        {{ $t("Stacks") }}
      </BaseHeading>

      <div class="mb-8 flex gap-4">
        <div class="flex flex-col gap-4 md:flex-row">
          <div
            class="flex basis-1/2 flex-wrap justify-center gap-8 rounded-2xl border-2 border-white p-4 text-3xl font-light shadow-md dark:shadow-none"
          >
            <BaseHeading
              tag="h4"
              class="basis-full"
            >
              {{ $t("Frontend") }}
            </BaseHeading>
            <Icon
              v-for="(icon, index) in frontEndIcons"
              :key="'f' + index"
              size="64"
              :name="icon"
            />
          </div>
          <div
            class="flex basis-1/2 flex-wrap justify-center gap-8 rounded-2xl border-2 border-white p-4 text-3xl font-light shadow-md dark:shadow-none"
          >
            <BaseHeading
              tag="h4"
              class="basis-full"
            >
              {{ $t("Backend") }}
            </BaseHeading>
            <Icon
              v-for="(icon, index) in backendIcons"
              :key="'b' + index"
              :name="icon"
              size="64"
            />
          </div>
        </div>
      </div>

      <div
        class="mx-auto flex flex-wrap justify-center gap-8 rounded-2xl border-2 border-white p-4 text-3xl font-light text-neutral-50 shadow-md dark:shadow-none lg:max-w-lg lg:basis-1/2"
      >
        <BaseHeading
          tag="h4"
          class="basis-full"
        >
          {{ $t("Software") }}
        </BaseHeading>

        <Icon
          v-for="(icon, index) in techIcons"
          :key="'s' + index"
          class="text-neutral-950 dark:text-neutral-50"
          :name="icon"
          size="64"
        />
      </div>
    </div>
  </section>
</template>
