<script lang="ts" setup>
// ************* TYPES ************* //
interface Props {
  variant?: 'solid' | 'outline' | 'soft' | 'subtle'
  color?: 'green' | 'blue' | 'yellow'
  square?: boolean
  leadingIcon?: string
}

// ************* PROPS ************* //
const props = withDefaults(defineProps<Props>(), {
  color: 'blue',
})

const variantClass = computed(() => {
  if (props.color === 'green') {
    return {
      solid: 'bg-green-600 text-white',
      outline: 'border border-green-500 text-green-500',
      soft: 'bg-green-950 text-green-200',
      subtle: 'bg-green-950 text-green-50',
    }[props.variant || 'solid']
  }

  return {
    solid: 'bg-blue-600 text-white',
    outline: 'border border-blue-500 text-blue-500',
    soft: 'bg-blue-950 text-blue-200',
    subtle: 'bg-blue-950 text-blue-50',
  }[props.variant || 'solid']
})
</script>

<template>
  <span
    class="flex items-center justify-center rounded-3xl text-base -:px-2 -:py-1"
    :class="[variantClass, { 'p-2': square }]"
  >
    <Icon
      v-if="leadingIcon"
      :name="leadingIcon"
    />
    <slot />
  </span>
</template>
