<script setup lang="ts">
// ************* TYPES ************* //
import { useDateFormat } from '@vueuse/core'

interface Props {
  date: Date | string
  format?: string
  icon?: string
}

// ************* PROPS ************* //
const props = withDefaults(defineProps<Props>(), {
  format: 'DD.MM.YYYY',
  icon: 'i-heroicons-calendar-days',
})

const formatedDate = useDateFormat(props.date, props.format)
</script>

<template>
  <span class="flex items-center gap-2">
    <Icon
      class="icon"
      size="24"
      :name="icon"
    />
    <time
      class="text-base"
      :datetime="date"
    >
      {{ formatedDate }}
    </time>
  </span>
</template>

<style scoped lang="scss"></style>
