<script setup lang="ts">
import BaseHeading from '#/domains/base/components/BaseText/BaseHeading.vue'

// ************* TYPES ************* //
interface Props {
  title: string
  category?: string
  date?: string
}

// ************* PROPS ************* //
defineProps<Props>()

function convertTimestampToDate(date) {
  const timestamp = new Date(date)
  const day = timestamp.getDay()
  const month = timestamp.getMonth()
  const year = timestamp.getFullYear()
  return `${day}.${month}.${year}`
}
</script>

<template>
  <header class="grid grid-cols-2 items-center">
    <BaseHeading
      tag="h3"
      class="col-span-2 mb-1 line-clamp-2 text-3xl font-medium dark:text-neutral-100"
    >
      {{ title }}
    </BaseHeading>

    <BaseHeading
      v-if="category"
      tag="h4"
      class="col-span-1 text-xl"
    >
      {{ category }}
    </BaseHeading>
    <time
      v-if="date"
      class="col-span-1 text-right text-base text-neutral-950 dark:text-neutral-100"
    >{{ convertTimestampToDate(date) }}</time>
  </header>
</template>

<style scoped lang="scss"></style>
