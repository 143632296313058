<template>
  <div>
    <svg
      class="waves opacity-20 dark:opacity-10"
      preserveAspectRatio="none"
      shape-rendering="auto"
      viewBox="0 24 150 28"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
        />
      </defs>
      <g class="parallax">
        <use
          class="fill-blue-950 dark:fill-secondary-950"
          x="48"
          xlink:href="#gentle-wave"
          y="0"
        />
        <use
          class="fill-blue-900 dark:fill-secondary-900"
          x="48"
          xlink:href="#gentle-wave"
          y="3"
        />
        <use
          class="fill-blue-800 dark:fill-secondary-800"
          x="48"
          xlink:href="#gentle-wave"
          y="5"
        />
        <use
          class="fill-blue-700 dark:fill-secondary-700"
          x="48"
          xlink:href="#gentle-wave"
          y="7"
        />
      </g>
    </svg>
  </div>
</template>
