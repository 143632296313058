<script lang="ts" setup>
import { gsap } from 'gsap'
import { ref, onMounted } from 'vue'
import { useAppStore } from '#/stores/appStore'
import BaseCookie from '#components/modals/CookieModal/BaseCookie.vue'
import BaseHeading from '#/domains/base/components/BaseText/BaseHeading.vue'

const tl = gsap.timeline({ duration: 0.75, ease: 'Power1.easeOut' })

const appStore = useAppStore()
const { state } = storeToRefs(appStore)

const cookieComponentInstance = ref<InstanceType<typeof BaseCookie>>()
const $acceptBtn = ref()
const $cookieContainer = ref<HTMLElement>()

function initTimeline() {
  if (!cookieComponentInstance.value) return

  const { $crumbs, $el } = cookieComponentInstance.value

  tl.fromTo(
    $cookieContainer,
    { scale: 0.6 },
    { scale: 1, ease: 'elastic.easeOut.config(1, 0.4)', duration: 1.5 },
  )
  tl.fromTo(
    $el as SVGAElement,
    { opacity: 0, x: -50, rotation: '-45deg' },
    { opacity: 1, x: 0, rotation: '0deg' },
    '<90%',
  )
  tl.fromTo('.text-wrapper', { x: 30, opacity: 0 }, { x: 0, opacity: 1 }, '<')

  // Cookie jump
  tl.fromTo(
    $el as SVGAElement,
    { y: 0, rotation: '0deg' },
    { y: -20, rotation: '-20deg', yoyo: true, repeat: -1 },
  )
  tl.fromTo(
    $crumbs as SVGAngle,
    { y: 0 },
    { y: -10, yoyo: true, repeat: -1 },
    '<',
  )

  // Pulsate button
  tl.fromTo(
    $acceptBtn.value.$el,
    { scale: 0.95, opacity: 0.6 },
    {
      scale: 1,
      opacity: 1,
      repeat: -1,
      yoyo: true,
    },
  )
}

onMounted(() => {
  nextTick(() => {
    if (state.value.showCookieMsg) {
      initTimeline()
    }
    else {
      $cookieContainer.value?.remove()
    }
  })
})
</script>

<template>
  <teleport to="body">
    <transition
      name="roll-from-left"
      appear
    >
      <div
        v-if="state.showCookieMsg"
        ref="$cookieContainer"
        class="fixed bottom-4 left-1/2 z-50 flex w-full -translate-x-1/2 items-center justify-center gap-5 rounded-lg bg-blue-50 p-8 shadow shadow-blue-200 dark:bg-black dark:shadow-primary-950 md:w-auto md:justify-start"
      >
        <BaseCookie
          ref="cookieComponentInstance"
          class="mr-4"
        />

        <div>
          <BaseHeading
            tag="h3"
            class="text-2xl"
          >
            {{ $t('Cookies') }}
          </BaseHeading>

          <div
            class="flex items-center justify-center gap-2 text-lg text-neutral-900 dark:text-neutral-100"
          >
            <NuxtLink
              class="hover-underline"
              to="/cookie"
            >
              {{ $t('Policy') }}
            </NuxtLink>

            <span>/</span>

            <NuxtLink
              class="hover-underline text-base"
              to="/privacy"
            >
              {{ $t('Privacy') }}
            </NuxtLink>
          </div>
        </div>

        <BaseButton
          id="accept-btn"
          ref="$acceptBtn"
          class="accept-btn"
          color="neutral"
          size="md"
          @click="
            appStore.$patch(({ state }) => {
              state.showCookieMsg = false
            })
          "
        >
          {{ $t('Accept') }}
        </BaseButton>
      </div>
    </transition>
  </teleport>
</template>
