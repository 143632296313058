<script setup lang="ts">
import { computed } from 'vue'
import BaseDate from '#/domains/base/components/BaseDate/BaseDate.vue'
import { FrameworkType, type Maybe, type Project } from '#/graphql/types'

// ************* PROPS ************* //
const props = defineProps<Project>()

const getFrameWorkIcon = (framework: Maybe<FrameworkType>) => {
  switch (framework) {
    case 'vue':
      return 'i-logos-vue'
    case 'nuxt':
      return 'i-logos-nuxt-icon'
    case 'rubyOnRails':
      return 'i-logos-ruby'
    case 'vitest':
      return 'i-logos-vitest'
    case 'docker':
      return 'i-logos-docker-icon'
    case 'tailwindcss':
      return 'i-logos-tailwindcss-icon'
    case 'graphql':
      return 'i-logos-graphql'
    case 'laravel':
      return 'i-logos-laravel'
    case 'nodejs':
      return 'i-logos-nodejs'
    case 'supabase':
      return 'i-logos-supabase-icon'
    case 'stripe':
      return 'i-logos-stripe'
    case 'vite':
      return 'i-logos-vitejs'
    case 'typescript':
      return 'i-logos-typescript-icon-round'
    case 'react':
      return 'i-logos-react'
    case 'postgresql':
      return 'i-logos-postgresql'
    case 'express':
      return 'i-logos-express'
    case 'mongodb':
      return 'i-logos-mongodb-icon'
    case 'cypress':
      return 'i-logos-cypress-icon'
    case 'testingLibrary':
      return 'i-logos-testing-library'
    default:
      return ''
  }
}

const getStatusColor = (status: string) => {
  switch (status) {
    case 'completed':
      return 'green'
    case 'active':
      return 'yellow'
  }
}

const roleName = computed(() => {
  return props.role ? props.role.split(/(?=[A-Z])/).join(' ') : ''
})
</script>

<template>
  <div class="space-y-4 pt-4 text-neutral-950 dark:text-neutral-50">
    <BaseDate
      :label="$t('Start Date')"
      :date="startDate"
    />

    <BaseDate
      v-if="endDate"
      icon="i-heroicons-calendar"
      :date="endDate"
    />

    <div
      class="flex items-center gap-2"
      :class="{ 'font-bold': status === 'active' }"
    >
      <BaseBadge
        square
        class="shrink-0"
        :color="getStatusColor(status)"
        variant="subtle"
        leading-icon="i-heroicons-clipboard-document"
      />
      <BaseText>{{ status }}</BaseText>
    </div>

    <div
      v-if="license"
      class="flex items-center gap-2"
      :class="{ 'font-bold': status === 'active' }"
    >
      <Icon
        class="flex-shrink-0"
        name="i-material-symbols-license-outline-rounded"
        size="24"
      />
      <BaseText>{{ license }}</BaseText>
    </div>

    <div class="mb-4 flex items-center gap-2">
      <Icon
        class="flex-shrink-0"
        name="i-heroicons-document-text"
        size="24"
      />
      <BaseText>{{ roleName }}</BaseText>
    </div>

    <div
      v-if="technology"
      role="list"
      class="flex max-w-md flex-wrap justify-center gap-4 pb-4"
    >
      <Icon
        v-for="(tech, index) in technology"
        :key="`${tech}-${index}`"
        role="listitem"
        class="shrink-0 text-2xl"
        :name="getFrameWorkIcon(tech)"
      />
    </div>

    <BaseText class="mb-4 text-left">
      {{ description }}
    </BaseText>

    <slot name="infos" />
  </div>
</template>
