<script setup lang="ts">
import { ref } from 'vue'
import { useIntersectionObserver } from '@vueuse/core'
import BaseHeading from '#/domains/base/components/BaseText/BaseHeading.vue'
import TheSphere from '#components/sections/hero/TheSphere.vue'

const heroTarget = ref<HTMLElement>()

const heroTargetIsVisible = useState<boolean>('apply-sticky-effect', () =>
  ref(false),
)

useIntersectionObserver(heroTarget, ([{ isIntersecting }]) => {
  heroTargetIsVisible.value = isIntersecting
})
</script>

<template>
  <section
    ref="heroTarget"
    class="flex flex-col lg:flex-row"
  >
    <article class="basis-full md:basis-1/2">
      <BaseHeading
        tag="h1"
        class="mb-8 flex flex-col"
      >
        <span class="text-3x mb-1 text-neutral-700 lg:text-4xl">{{
          $t('Full-Stack Web Developer')
        }}</span>
        <span
          class="text-6xl font-bold text-neutral-950 dark:text-neutral-50 lg:text-8xl"
        >
          {{ $t('Benjamin Scharf') }}
        </span>
      </BaseHeading>
      <div class="left-line flex h-20 max-w-sm gap-3 text-xl">
        <BaseText
          tag="p"
          class="text-neutral-300"
        >
          {{
            $t(
              'Living on the sunny beautiful side of the web. Working with the latest technologies to create the best user experience.',
            )
          }}
        </BaseText>
      </div>
    </article>

    <div class="hidden md:block md:basis-1/2">
      <ClientOnly>
        <TheSphere />
      </ClientOnly>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.left-line {
  &:before {
    @apply h-full bg-neutral-700 dark:bg-neutral-50;
    content: '';
    width: 1px;
    border-radius: 100%;
  }
}
</style>
