<script lang="ts" setup>
import { useBreakpoints } from '@vueuse/core'
import BaseHeading from '#/domains/base/components/BaseText/BaseHeading.vue'
import ProjectSlide from '#/components/library/projectCarousel/ProjectSlide.vue'
import { useProjectsQuery } from '#/graphql/operations/queries/projects.api'
import type { Project } from '#/graphql/types'

const { result, onError } = useProjectsQuery()

const router = useRouter()
const breakpoints = useBreakpoints({ tablet: 1000 })

onError((error) => {
  console.error(error)
})

type Events = 'open-modal'

interface Info {
  title: string
  id: string
}

interface HandleEvents {
  eventName: Events
  info: Info
}

const handleEvents = ({ eventName, info: { title, id } }: HandleEvents) => {
  if (eventName === 'open-modal') {
    router.push({
      name: breakpoints.isGreaterOrEqual('tablet')
        ? 'index-project-name-id'
        : 'project-name-id',
      params: { name: title, id: id },
    })
  }
}
</script>

<template>
  <section
    id="projects"
    class="flex justify-center text-center"
  >
    <Transition
      name="fade-up"
      appear
    >
      <div>
        <BaseHeading
          class="mb-4 text-5xl font-semibold text-neutral-950 -:font-medium -:text-neutral-950 dark:text-white -:dark:text-neutral-300"
        >
          {{ $t('Projects') }}
        </BaseHeading>
        <div
          v-if="result?.projects"
          class="max-w-[100vw] lg:max-w-6xl"
        >
          <Carousel
            :breakpoints="{
              1024: { itemsToShow: 3 },
              700: { itemsToShow: 2 },
              300: { itemsToShow: 1 },
            }"
            :arrow="false"
            snap-align="center"
          >
            <Slide
              v-for="(project, index) in result.projects"
              :key="(project?.id as string) + index"
            >
              <div class="h-full p-4">
                <ProjectSlide
                  :project="project as Project"
                  @handle:events="handleEvents($event)"
                />
              </div>
            </Slide>

            <template #addons>
              <Navigation />
              <Pagination />
            </template>
          </Carousel>
        </div>
      </div>
    </Transition>
  </section>
</template>

<style scoped>
:deep(.carousel__viewport) {
  @apply py-8;
}
</style>
