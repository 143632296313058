<template>
  <div class="relative pb-60">
    
<FormKitLazyProvider config-file="true">
<FormKit
      ref="form"
      :actions="false"
      type="form"
      @submit="submit"
    >
      <div class="field-animate mb-4 flex flex-col gap-4 md:flex-row">
        <FormKit
          :classes="{ outer: 'max-w-none' }"
          label="Name"
          validation="required"
          type="text"
          placeholder="Elon Musk"
          name="username"
        />
        <FormKit
          :classes="{ outer: 'max-w-none' }"
          label="Email"
          validation="required|email"
          placeholder="x@space.com"
          type="email"
          name="email"
        />
      </div>

      <div class="field-animate mb-4 flex flex-col gap-4 md:flex-row">
        <FormKit
          :classes="{ outer: 'max-w-none' }"
          label="Phone"
          type="text"
          placeholder="+34 1234242"
          name="phone"
        />
        <FormKit
          :classes="{ outer: 'max-w-none' }"
          label="Subject"
          type="text"
          validation="required"
          placeholder="Job offer"
          name="subject"
        />
      </div>

      <div class="field-animate">
        <FormKit
          :classes="{ outer: 'max-w-none' }"
          label="Message"
          placeholder="Hello, space X would like to take you for a trip to Mars."
          validation="required"
          type="textarea"
          name="message"
        />
      </div>
      <BaseButton
        class="field-animate"
        type="submit"
      >
        Submit
      </BaseButton>
    </FormKit>
    <FormCharacter
      class="absolute -bottom-[5.5rem] right-0 h-80 overflow-visible"
    />
</FormKitLazyProvider>

  </div>
</template>

<script lang="ts" setup>
import { FormKitLazyProvider } from '@formkit/vue'
import { onMounted } from 'vue'
import { gsap } from 'gsap'

import FormCharacter from '#components/library/illustrations/FormCharacter.vue'
import { useAppStore } from '#/stores/appStore'
import useMessageStore from '#/stores/messageStore'

const formContact = ref<HTMLFormElement | null>(null)
const form = ref()
// ************* STORES ************* //
const { state: appState } = useAppStore()
const { submitForm } = useMessageStore()
const { call } = useApi()

const mail = useMail()

/// ////////////////////
// Animating Character
/// ////////////////////
async function animateCharacter() {
  if (!document) {
    return
  }
  const characterAnimation = function (entries) {
    if (!appState.nodes.contactPage) return

    const [entry] = entries
    if (!appState.nodes.character.eyeRight) return
    if (entry.isIntersecting) {
      // TimeLines
      const tlFace = gsap.timeline({ default: { duration: 3 } })
      // Line path for the smiling mouth
      const start = 'M195.5 118C190.333 118.255 187.471 118.434 182 118'
      const end = 'M195.5 118C190.356 120.454 187.563 120.771 182 118'
      // Animation for the right eye
      gsap.set(appState.nodes.character.eyeRight, {
        transformOrigin: 'center',
      })
      tlFace.fromTo(
        appState.nodes.character.eyeRight,
        { scaleY: 1, repeat: -1, repeatDelay: 2 },
        { scaleY: 0.6, repeat: -1, yoyo: true, repeatDelay: 2 },
      )
      // Animation for the mouth
      tlFace.fromTo(
        appState.nodes.character.mouth,
        {
          attr: { d: start },
          repeatDelay: 2,
        },
        { attr: { d: end }, yoyo: true, repeat: -1, repeatDelay: 2 },
        '<',
      )
    }
  }
  // characterAnimation()
}

/// ///////////////////
// Animation for form on click
/// /////////////////////
function formSubmissionAnimation() {
  const submitTl = gsap.timeline({
    defaults: { duration: 0.75, ease: 'Power2.easeOut' },
  })
  const endPath = 'M195.5 118C190.356 120.454 187.563 120.771 182 118'

  submitTl.to('.field-animate', {
    y: 30,
    opacity: 0,
    pointerEvents: 'none',
  })

  submitTl.to(
    formContact.value,
    {
      scale: 0.8,
    },
    '<',
  )

  gsap.set(appState.nodes.character.armLeft, { transformOrigin: 'right' })
  submitTl.fromTo(
    appState.nodes.character.armLeft,
    { rotate: 0 },
    { rotate: -10, ease: 'elastic.out(3, 0.5)', duration: 2, delay: 1 },
  )

  submitTl.to(appState.nodes.character.mouth, { attr: { d: endPath } })

  submitTl.fromTo(
    form.value,
    { opacity: 0, height: 0, width: 0 },
    { opacity: 1, height: 'min-content', width: '80%' },
    '<',
  )
  submitTl.fromTo(
    '.animate',
    { opacity: 0, scale: 0.8 },
    { opacity: 1, scale: 1 },
  )
  gsap.set(appState.nodes.character.cloud, { zIndex: 4 })
  submitTl.fromTo(
    appState.nodes.character.cloud,
    { y: 0, x: 0, opacity: 1 },
    { y: '-1000%', x: '-200%', opacity: 0, duration: 20 },
    '<',
  )
}

const adminEmail = import.meta.env.VITE_ADMIN_EMAIL

const submit = async (values) => {
  try {
    const text = `email of ${values.email} \n \n name: ${values.name} \n \n phone number: ${values.phoneNumber} \n \n ${values.message}`
    const receiver = {
      from: values.email,
      to: adminEmail,
      subject: values.subject,
      phoneNumber: values.phoneNumber,
      text,
    }
    await call(
      async () =>
        await Promise.all([
          mail.send(receiver),
          submitForm({ adminEmail: adminEmail, ...values }),
        ]),
    )
    formSubmissionAnimation()
  }
  catch (e) {
    console.error(e)
  }
}

onMounted(() => {
  animateCharacter()
})
</script>
