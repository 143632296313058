import { defineStore, acceptHMRUpdate } from 'pinia'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDark } from '@vueuse/core'

export const useChartStore = defineStore('chart', () => {
  const { t } = useI18n()
  const isDark = useDark()
  const barData = computed(() => ({
    labels: [
      t('Communication'),
      t('Teamwork'),
      t('Solving'),
      t('Initiative'),
      t('Learning'),
      t('Planning'),
      t('Self-management'),
      t('Expertise'),
      t('Adaptability'),
    ],
    datasets: [
      {
        label: '%',
        backgroundColor: isDark.value
          ? 'rgba(212,163,222,0.4)'
          : 'rgba(212,163,222, 0.6)',
        hoverBackgroundColor: isDark.value
          ? 'rgb(212,163,222)'
          : 'rgb(255,255,255, 1)',
        borderColor: ['#fff'],
        // barPercentage: 2,
        borderWidth: 1,
        borderRadius: 10,
        data: [50, 75, 80, 60, 70, 45, 39, 80, 85, 90, 100, 70],
        color: '#fff',
      },
    ],
  }))
  const polarData = computed(() => ({
    labels: [
      'Coding',
      'Sleeping',
      'Designing',
      'Sport',
      'Organising',
      'Private',
    ],
    datasets: [
      {
        label: 'Hours per week',
        backgroundColor: [
          '#a542b8',
          '#6bbbf6',
          '#f0b800',
          '#d81159',
          '#ddf4ea',
          '#3E3F35FF',
        ],
        minBarLength: 78,
        barPercentage: 0.1,
        pointBackgroundColor: '#f4c7a3',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(179,181,198,1)',
        data: [40, 42, 5, 10, 14, 54],
      },
    ],
  }))

  const pieData = computed(() => ({
    labels: ['VueJs', 'Reactjs', 'Express', 'Ruby on Rails', 'Laravel'],
    datasets: [
      {
        backgroundColor: [
          '#4BB483FF',
          '#00D1F7FF',
          '#285e43',
          '#9f0420',
          '#F72C1FFF',
        ],
        data: [65, 5, 20, 5, 5],
      },
    ],
  }))

  const options = {
    // animations: {
    //   tension: {
    //     duration: 1000,
    //     easing: 'linear',
    //     from: 1,
    //     to: 0,
    //     loop: true,
    //   },
    // },
    plugins: {
      legend: {
        labels: {
          color: '#ffffff',
          font: {
            size: 18,
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          color: '#b3b3b3',
          font: {
            size: 14,
            family: 'Space Grotesk, sans-serif',
          },
          stepSize: 1,
          beginAtZero: true,
        },
      },
      x: {
        ticks: {
          color: '#b3b3b3',
          font: {
            size: 14,
            family: 'Space Grotesk, sans-serif',
          },
          stepSize: 1,
          beginAtZero: true,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: true,
  }

  return { barData, polarData, pieData, options }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useChartStore, import.meta.hot))
}
