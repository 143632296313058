import gql from 'graphql-tag'
import * as VueApolloComposable from '@vue/apollo-composable'
import type * as VueCompositionApi from 'vue'
import type * as Types from '../../types'

export type ReactiveFunction<TParam> = () => TParam

export const ProjectsDocument = gql`
  query Projects {
    projects {
      name
      contact {
        name
        email
      }
      homepage
      endDate
      startDate
      blogPost
      technology
      license
      role
      screenshots
      id
      vip
      liveDemoUrl
      repositoryUrl
      status
      description
      tags {
        name
        id
        variant
      }
    }
  }
`

/**
 * __useProjectsQuery__
 *
 * To run a query within a Vue component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProjectsQuery();
 */
export function useProjectsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
      Types.ProjectsQuery,
      Types.ProjectsQueryVariables
    >
    | VueCompositionApi.Ref<
      VueApolloComposable.UseQueryOptions<
        Types.ProjectsQuery,
        Types.ProjectsQueryVariables
      >
    >
    | ReactiveFunction<
      VueApolloComposable.UseQueryOptions<
        Types.ProjectsQuery,
        Types.ProjectsQueryVariables
      >
    > = {},
) {
  return VueApolloComposable.useQuery<
    Types.ProjectsQuery,
    Types.ProjectsQueryVariables
  >(ProjectsDocument, {}, options)
}
export function useProjectsLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
      Types.ProjectsQuery,
      Types.ProjectsQueryVariables
    >
    | VueCompositionApi.Ref<
      VueApolloComposable.UseQueryOptions<
        Types.ProjectsQuery,
        Types.ProjectsQueryVariables
      >
    >
    | ReactiveFunction<
      VueApolloComposable.UseQueryOptions<
        Types.ProjectsQuery,
        Types.ProjectsQueryVariables
      >
    > = {},
) {
  return VueApolloComposable.useLazyQuery<
    Types.ProjectsQuery,
    Types.ProjectsQueryVariables
  >(ProjectsDocument, {}, options)
}
export type ProjectsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    Types.ProjectsQuery,
    Types.ProjectsQueryVariables
  >
