<template v-if="featuredPosts.length > 0">
  <div
    class="grid max-w-6xl gap-8 md:grid-cols-2 lg:[&>*:nth-child(odd)]:translate-y-1/4"
  >
    <article
      v-for="(blog, index) in featuredPosts"
      :key="index"
      :aria-poseinset="featuredPosts.length"
      :aria-setsize="index + 1"
    >
      <FeaturedBlogCard
        role="listitem"
        class="col-span-1"
        :blog="blog"
      />
    </article>
  </div>
</template>

<script lang="ts" setup>
import { watchEffect } from 'vue'
import type { FeaturedBlog } from '#components/sections/blogs/types.ts'

const featuredPosts = ref<FeaturedBlog[]>([])
const featuredBlogs = await queryContent('blog')
  .only([
    'title',
    'alt',
    'blurb',
    'category',
    'hashtags',
    'published_at',
    'featured_at',
    '_path',
    'thumbnail',
    'tags',
  ])
  .find()

watchEffect(() => {
  if (featuredBlogs) {
    featuredPosts.value = featuredBlogs.filter(d => d.featured_at)
  }
})
</script>
